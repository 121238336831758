import React, { useEffect, useState } from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Create_order_nav from './Create_order_nav'
import axios from 'axios'
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';


import Swal from 'sweetalert2'

import ReactLoading from 'react-loading';
import config from "../../config"
import { useNetworkState } from 'react-use';
import useRazorpay from "react-razorpay";

const Wallet_recharge = () => {
    const isOnline = useNetworkState();
    console.log("isONline  === ", isOnline)
    let userDetail = localStorage.getItem('ship_rocket_user');
    let navigate = useNavigate()
    let location = useLocation()
    let userData = {};
    if (userDetail != null) {
        userData = JSON.parse(userDetail)
    }
    const [state, setState] = useState({ wallet_balance: 0, recharge_amount: 300, mode_type: "169302936500", payementGateway: [] })
    const [balancestate, setbalanceState] = useState({ add_balance: [], total_balance: [], total_credit: 0, total_debit: 0 })
    const [Razorpay, isLoaded] = useRazorpay();
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };

    useEffect(() => {
        get_wallet_balance()

    }, [])

    const get_wallet_balance = () => {
        let dataToSend = { customer_id: userData.customer_id };

        // console.log("datatoSend", dataToSend)
        let url = config.apiUrl + '/user/get_wallet_balance';
        axios.post(url, dataToSend, { headers: config.headers })
            .then((res) => {
                console.log("RE responseJson => ", res);
                if (res.data.status == true) {
                    setbalanceState({ add_balance: res.data.added_wallet_transactions, total_balance: res.data.find_customer_wallet, total_credit: res.data.credit_value, total_debit: res.data.debit_value })
                    //wallet_balance: res.data.find_customer_wallet[0].wallet_balance,
                    setState({ ...state, payementGateway: res.data.payementGateway })
                }
                else {
                    console.log("wrong");
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000
                    })
                    Toast.fire({
                        background: "rgb(231, 91, 75)",
                        type: 'Unsuccess',
                        title: "Something Went Wrong !",
                        color: "white"
                    });
                }
            }).catch((error) => {
                console.log("wrong ", error);
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
                Toast.fire({
                    background: "rgb(231, 91, 75)",
                    type: 'Unsuccess',
                    title: "Something Went Wrong !",
                    color: "white"
                });
                // console.log(error);
            });

    }

    const submit_payment = () => {
        // console.log(window.location.origin)
        //   // console.log(location);

        if (state.recharge_amount >= 300) {
            let full_api = config.apiUrl + `/user/payment_phone_pay`;
            let sendData = { total_amount: state.recharge_amount, pid: state.mode_type, redirectUrl: window.location.origin + "/custom.html" };
            console.log("h ", config.headers);
            axios.post(full_api, sendData, { headers: config.headers }).then(res => {
                console.log("rnmmmmm", res);
                let resData = res.data.output;
                if (resData.pid == "169302936500") {
                    window.location.replace(resData.instrumentResponse.redirectInfo.url);
                } else if (resData.pid == "169302936502") {
                    pay_using_razorpay(resData.order_id, state.recharge_amount, resData.transaction_id , resData.gateway_id)
                }
                // navigate("/" + resData.instrumentResponse.redirectInfo.url, { replace: true })
                // navigate('/customer/Wallet_payment_link_show', { state: { payment_url: resData.instrumentResponse.redirectInfo.url, transaction_id: resData.transaction_id } })
            }).catch((e) => {
                // toast.configure()
                //toast.error("Some thing went wrong")
                // console.log("----error:   ", e);
            })
        } 
        else {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
            })

            Toast.fire({
                background: "rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Please Enter Valid Recharge amount. ",
                color: "white"
            });

        }
    }

    const pay_using_razorpay = (order_id, amount, transaction_id , gateway_id) => {
console.log("order_ida === " , order_id)
        const options = {
            key: gateway_id, // Enter the Key ID generated from the Dashboard
            amount: amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "RocketXpress",
            description: "Wallet Recharge",
            image: "https://app.myrocketxpress.life/img/logo_3.png",
            order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
            handler: function (response) {
                razorPaymentVerify({ payment_response: response, amount: amount, transaction_id: transaction_id, order_id: order_id })
                //  alert(response.razorpay_payment_id);
                //  alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
            },
            prefill: {
                name: userData.full_name,
                email: userData.email,
                contact: userData.mobile,
            },
            notes: {
                address: userData.mobile,
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        });
        rzp1.open();

    }

    const razorPaymentVerify = (dataToSend) => {
        //   let dataToSend = { customer_id: userData.customer_id };

        console.log("datatoSend", dataToSend)
        let url = config.apiUrl + '/user/payment_razor_pay_verify';
        axios.post(url, dataToSend, { headers: config.headers })
            .then((res) => {
                console.log("verify responseJson => ", res);
                if (res.data.status == true) {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000
                    })
                    Toast.fire({
                        background: "#1EA11A",
                        type: 'success',
                        title: "Wallet Amount Added",
                        color: "white"
                    });
navigate('/customer/dashboard')
                    //  setState({ ...state, payementGateway: res.data.payementGateway })
                }
                else {
                    console.log("wrong");
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000
                    })
                    Toast.fire({
                        background: "rgb(231, 91, 75)",
                        type: 'Unsuccess',
                        title: "Something Went Wrong !",
                        color: "white"
                    });
                }
            }).catch((error) => {
                console.log("wrong ", error);
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                })
                Toast.fire({
                    background: "rgb(231, 91, 75)",
                    type: 'Unsuccess',
                    title: "Something Went Wrong !",
                    color: "white"
                });
                // console.log(error);
            });

    }

    const handleInput = (e) => {
        // console.log(e);
        setState({ ...state, [e.target.name]: e.target.value })
    }


    return (
        <div >
        <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
         
          <Header/>
                <section class="mb-3 recharge_wallet">
                    <div class="">
                        <div class="row justify-content-center">
                            <div class="col-12 col-md-5">
                                <div class="card my-4 border-0 shadow-lg rounded-5 p-3">
                                    <div class="card-body">
                                        <h3 class="pt-3">Recharge Your Wallet</h3>
                                        <small>Current Wallet Amount  <span class="text-success">₹{state.wallet_balance.toFixed(2)}</span></small>
                                        <div class="card mt-3 recharge_box_2 rounded-5 py-3">
                                            <div class="card-body">
                                                <div class="mb-3">
                                                    <label for="email" class="form-label">Enter Amount Below</label>
                                                    <div class="input-group input-group-lg">
                                                        <span class="input-group-text">&#8377;</span>
                                                        <input type="text" class="form-control" placeholder="Enter value" name="recharge_amount" value={state.recharge_amount} onChange={(e) => handleInput(e)} />
                                                    </div>
                                                    <small class="text-muted">Min value:₹500 & Max value: ₹50,00,000</small>
                                                </div>
                                                <p>Or Select From Below</p>
                                                <div class="mb-3">
                                                    <a href="#" class=""><span class="badge rounded-pill" onClick={() => setState({ ...state, recharge_amount: 500 })}>Rs. 500</span></a>
                                                    <a href="#" class=""><span class="badge rounded-pill" onClick={() => setState({ ...state, recharge_amount: 1000 })}>Rs. 1000</span></a>
                                                    <a href="#" class=""><span class="badge rounded-pill" onClick={() => setState({ ...state, recharge_amount: 1500 })}>Rs. 1500</span></a>
                                                    <a href="#" class=""><span class="badge rounded-pill" onClick={() => setState({ ...state, recharge_amount: 2000 })}>Rs. 2000</span></a>
                                                    <a href="#" class=""><span class="badge rounded-pill" onClick={() => setState({ ...state, recharge_amount: 5000 })}>Rs. 5000</span></a>
                                                </div>
                                                <p>Select Payment Mode</p>
                                                <div>
                                                     {state.payementGateway.map((item) =>
                                                        <>
                                                            <input type="radio" name="mode_type" value={item.pid} onChange={(e) => handleInput(e, item)} checked={state.mode_type === item.pid} /> {item.name}<br />
                                                        </>
                                                    )} 
                                                     {/* <input type="radio" name="mode_type" onChange={() => handleInput(2)} checked={state.mode_type === 2} /> Paypal<br />
                                                    <input type="radio" name="mode_type" onChange={() => handleInput(3)} checked={state.mode_type === 3} /> PhonePay  */}


                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-grid pt-3">
                                            <button class="btn btn-primary btn-block" onClick={() => submit_payment()}>Continue to Payments</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
           

            </div>
            {/* <?php include 'footer.php';?> */}
        </div >
    )
}

export default Wallet_recharge
